import React from "react";
import { useNavigate } from "react-router-dom";

const TableLandingPage = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  // Redirect to Feedback Form
  const goToFeedback = () => {
    navigate("/feedback");
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-darkGreen to-gray-900 flex items-center justify-center">
      <div className="bg-white rounded-2xl shadow-2xl p-12 max-w-md w-full text-center">
        <h1 className="text-5xl font-extrabold text-mustard mb-6 tracking-tight">
          Welcome!
        </h1>
        <p className="text-gray-500 text-lg mb-10">
          Karibu I ❤️ Sagana ! <br />
          What would you like to do next? If you are not sure please ask for
          either Byron (our restaurant manager) or Dennis (our head of customer
          service) for assistance.
        </p>

        {/* Menu Button */}
        <button
          onClick={() =>
            window.open("https://sagumo.com/leki-menu-combined.pdf", "_blank")
          }
          className="w-full py-3 border-2 border-mustard text-mustard font-semibold rounded-xl hover:bg-mustard hover:text-darkGreen transition-all duration-300 transform hover:scale-105 shadow-lg mb-5"
        >
          View Menu
        </button>

        {/* Feedback Button */}
        <button
          onClick={goToFeedback}
          className="w-full py-3 border-2 border-green-500 text-green-500 font-semibold rounded-xl hover:bg-green-500 hover:text-white transition-all duration-300 transform hover:scale-105 shadow-lg mb-5"
        >
          Provide Feedback
        </button>

        {/* Payment Button */}
        <button
          className="w-full py-3 border-2 border-gray-400 text-gray-400 font-semibold rounded-xl bg-gray-200 cursor-not-allowed opacity-50"
          disabled
        >
          Pay (Coming Soon)
        </button>
      </div>
    </div>
  );
};

export default TableLandingPage;
