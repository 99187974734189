import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-9xl font-extrabold text-white">404</h1>
        <p className="text-gray-400 text-lg mt-4">Oops! The page you're looking for doesn't exist.</p>
        <Link to="/">
          <button className="mt-6 px-6 py-3 bg-gradient-to-r from-pink-500 to-red-500 text-white text-lg font-semibold rounded-full shadow-lg hover:scale-105 transition-transform">
            Go Back Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
