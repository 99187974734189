import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = 'http://localhost:5005/api/tables';

interface Table {
  tableId: number;
  number: number;
  qrCodeUrl: string; // Base64-encoded QR code
}

const TableList: React.FC = () => {
  const [tables, setTables] = useState<Table[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch the table data from the backend
  const fetchTables = async () => {
    console.log('are we fetching tables?');
    try {
      const response = await axios.get(`${BASE_URL}`);
      setTables(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tables:', error);
      setLoading(false);
    }
  };

  // Run the fetchTables function once when the component is mounted
  useEffect(() => {
    fetchTables();
  }, []);

  // Function to generate tables (triggered by the button)
  const generateTables = async () => {
    try {
      const numberOfTables = prompt('Enter the number of tables to generate:', '10');
      if (numberOfTables) {
        await axios.post(`${BASE_URL}/generate-tables`, { numberOfTables: parseInt(numberOfTables) });
        fetchTables(); // Refetch tables after generation
      }
    } catch (error) {
      console.error('Error generating tables:', error);
    }
  };

  // Function to delete all tables
  const deleteAllTables = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete all tables? This action cannot be undone.');
    if (confirmDelete) {
      try {
        console.log(confirmDelete);
        await axios.delete(`${BASE_URL}/delete-tables/`);
        alert('All tables deleted successfully.');
        await fetchTables(); // Refetch tables after deletion
      } catch (error) {
        console.error('Error deleting tables:', error);
        alert('Failed to delete tables.');
      }
    }
  };

  // Function to download the QR code in the selected format (PNG/SVG)
  const downloadQRCode = (table: Table, format:string) => {
    const base64Data = table.qrCodeUrl; // Assuming you have the Base64 QR code here
    const fileName = `Table_${table.number}_QRCode`;
    
    // Determine the MIME type based on the requested format
    const mimeType = format === 'svg' ? 'image/svg+xml' : 'image/png';
  
    // Decode the Base64 string (without the data URL prefix)
    const byteCharacters = atob(base64Data.split(',')[1]);
  
    // Convert the Base64 string to a byte array
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
  
    // Create a blob from the byte array
    const blob = new Blob([byteArray], { type: mimeType });
  
    // Create a link element
    const link = document.createElement('a');
  
    // Create a URL for the Blob and set it as the href of the link
    link.href = URL.createObjectURL(blob);
  
    // Set the download attribute with the appropriate file extension
    link.download = `${fileName}.${format}`;
  
    // Append the link to the document body
    document.body.appendChild(link);
  
    // Programmatically click the link to trigger the download
    link.click();
  
    // Remove the link from the document
    document.body.removeChild(link);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log(tables, "here we are???")
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Table List</h1>

      {tables.length === 0 ? (
        // If there are no tables, show the "Generate Tables" button
        <div className="flex flex-col justify-center items-center h-screen">
          <button
            onClick={generateTables}
            className="px-6 py-3 bg-gradient-to-r from-mustard to-yellow-500 text-darkGreen font-semibold rounded-lg shadow-lg hover:scale-105 transition-transform mb-4"
          >
            Generate Tables
          </button>
        </div>
      ) : (
        <>
          {/* If tables exist, show the table list and delete button */}
          <div className="flex justify-end mb-4">
            <button
              onClick={deleteAllTables}
              className="px-6 py-3 bg-red-500 text-white font-semibold rounded-lg shadow-lg hover:bg-red-700 transition-transform"
            >
              Delete All Tables
            </button>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {tables.map((table) => (
              <div key={table.tableId} className="border p-4 rounded-lg shadow-lg text-center">
                <h2 className="text-xl font-semibold mb-2">Table {table.number}</h2>
                <img src={table.qrCodeUrl} alt={`QR Code for Table ${table.tableId}`} className="mb-4" />

                {/* Download Button with Dropdown for PNG/SVG */}
                <div className="relative inline-block text-left left-36">
                  <button
                    className="px-4 py-2 bg-mustard text-white font-bold rounded-lg hover:bg-yellow-600 transition-colors"
                    onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                  >
                    Download QR Code
                  </button>

                  <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg">
                    <button
                      onClick={() => downloadQRCode(table, 'png')}
                      className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 rounded-t-lg"
                    >
                      Download PNG
                    </button>
                    <button
              onClick={() => downloadQRCode(table, 'svg')}
                      className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 rounded-b-lg"
                    >
                      Download SVG
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TableList;