import React from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import Select from 'react-select';

// const BACKEND_URL= 'http://localhost:3000'

const FeedbackForm: React.FC = () => {
  // const { tableId } = useParams<{ tableId: string }>();
  // const [step, setStep] = useState<number>(1); // Step tracker
  // const [rating, setRating] = useState<number>(0);
  // const [foodQuality, setFoodQuality] = useState<number>(0);
  // const [serviceSpeed, setServiceSpeed] = useState<number>(0);
  // const [cleanliness, setCleanliness] = useState<number>(0);
  // const [comment, setComment] = useState<string>('');
  // const [additionalComment, setAdditionalComment] = useState<string>('');
  // const [recommend, setRecommend] = useState<boolean | null>(null);
  // const [message, setMessage] = useState<string>('');
  // const [waiters, setWaiters] = useState<{ value: string; label: string }[]>([]);
  // const [selectedWaiter, setSelectedWaiter] = useState<{ value: string; label: string } | null>(null);
  // const [tableInfo, setTableInfo] = useState(null);  // Store table info


  // useEffect(() => {
  //   const fetchWaiters = async () => {
  //     try {
  //       const response = await axios.get('http://localhost:3000/api/waiters');
  //       const waiterOptions = response.data.map((waiter: any) => ({
  //         value: waiter.id,
  //         label: waiter.name,
  //       }));
  //       setWaiters(waiterOptions);
  //     } catch (error) {
  //       console.error('Error fetching waiters', error);
  //     }
  //   };

  //   fetchWaiters();
  // }, []);


  // useEffect(() => {
  //   // Fetch the table info from the backend
  //   const fetchTableInfo = async () => {
  //     try {
  //       const response = await axios.get(`${BACKEND_URL}/api/tables/table-info/${tableId}`);
  //       console.log(response, "res")
  //       setTableInfo(response.data);  // Store the table info in state
  //     } catch (error) {
  //       console.error('Error fetching table info:', error);
  //     }
  //   };

  //   fetchTableInfo();  // Fetch info when component mounts
  // }, [tableId]);

  // const handleRating = (setter: React.Dispatch<React.SetStateAction<number>>, newRating: number) => {
  //   setter(newRating);
  // };

  // const handleSubmit = async (event: React.FormEvent) => {
  //   event.preventDefault();
  //   if (!selectedWaiter) {
  //     setMessage('Please select a waiter');
  //     return;
  //   }
  //   try {
  //     await axios.post(`http://localhost:3000/api/tables/${tableId}/feedback`, {
  //       rating,
  //       foodQuality,
  //       serviceSpeed,
  //       cleanliness,
  //       recommend,
  //       comment,
  //       additionalComment,
  //       waiterId: selectedWaiter?.value,
  //     });
  //     setMessage('Feedback submitted successfully!');
  //   } catch (error) {
  //     setMessage('Error submitting feedback');
  //   }
  // };

  // // Step navigation functions
  // const nextStep = () => setStep((prev) => prev + 1);
  // const prevStep = () => setStep((prev) => prev - 1);

  return (

      <div className="relative min-h-screen bg-darkGreen flex items-center justify-center py-12">
        {/* Curved Background Elements */}
        <div className="absolute top-0 left-0 w-1/2 h-1/3 bg-mustard rounded-br-full opacity-50"></div>
        <div className="absolute bottom-0 right-0 w-2/3 h-1/2 bg-mustard rounded-tl-full opacity-30"></div>
        <div className="relative bg-dullWhite shadow-2xl rounded-3xl p-10 max-w-lg w-full z-10">
          <div className="text-center">
            <h4 className="text-3xl font-bold text-darkGreen mb-4">Welcome to I ❤️ Sagana!</h4>
            <p className="text-gray-500 text-lg mb-6">
              We're excited to have you here. Please scan the menu to get started!
            </p>
            <button className="py-4 px-8 bg-mustard text-darkGreen font-bold rounded-xl shadow-lg hover:bg-darkGreen hover:text-mustard transition-all duration-300"
            onClick={() =>
              window.open("https://sagumo.com/leki-menu-combined.pdf", "_blank")
            }
            
            >
              Scan Menu
            </button>
          </div>
        </div>
      </div>
    // TODO: Bring this back when we are ready
    // <div className="relative min-h-screen bg-darkGreen flex  items-center justify-center py-12">
    //   {/* Curved Background Elements */}
    //   <div className="absolute top-0 left-0 w-1/2 h-1/3 bg-mustard rounded-br-full opacity-50"></div>
    //   <div className="absolute bottom-0 right-0 w-2/3 h-1/2 bg-mustard rounded-tl-full opacity-30"></div>
    //   <div className="relative bg-dullWhite shadow-2xl rounded-3xl p-10 max-w-lg w-full z-10">
    //     {/* Progress Indicator */}
    //     <div className="text-center mb-6">
    //       <p className="text-lg font-semibold text-darkGreen">
    //         Step {step} of 4
    //       </p>
    //     </div>

    //     <div className="text-center">
    //       <h4 className="text-3xl font-bold text-darkGreen mb-4">We Value Your Feedback!</h4>
    //       <p className="text-gray-500 text-lg mb-6">
    //         Help us improve by rating your experience at Table {tableId}, here at I ❤️ Sagana.
    //       </p>
    //     </div>

    //     {/* Step 1: Rating Section */}
    //     {step === 1 && (
    //       <div>
    //         <h2 className="text-2xl font-bold text-darkGreen mb-4 text-center">Rate Your Experience</h2>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Your Overall Experience</label>
    //           <div className="flex justify-center items-center">
    //             {[1, 2, 3, 4, 5].map((star) => (
    //               <svg
    //                 key={star}
    //                 className={`w-12 h-12 cursor-pointer transition-colors duration-300 ${rating >= star ? 'text-mustard' : 'text-gray-300 hover:text-mustard'
    //                   }`}
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //                 onClick={() => handleRating(setRating, star)}
    //               >
    //                 <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //               </svg>
    //             ))}
    //           </div>
    //         </div>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Food Quality</label>
    //           <div className="flex justify-center items-center">
    //             {[1, 2, 3, 4, 5].map((star) => (
    //               <svg
    //                 key={star}
    //                 className={`w-12 h-12 cursor-pointer transition-colors duration-300 ${foodQuality >= star ? 'text-mustard' : 'text-gray-300 hover:text-mustard'
    //                   }`}
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //                 onClick={() => handleRating(setFoodQuality, star)}
    //               >
    //                 <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //               </svg>
    //             ))}
    //           </div>
    //         </div>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Service Speed</label>
    //           <div className="flex justify-center items-center">
    //             {[1, 2, 3, 4, 5].map((star) => (
    //               <svg
    //                 key={star}
    //                 className={`w-12 h-12 cursor-pointer transition-colors duration-300 ${serviceSpeed >= star ? 'text-mustard' : 'text-gray-300 hover:text-mustard'
    //                   }`}
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //                 onClick={() => handleRating(setServiceSpeed, star)}
    //               >
    //                 <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //               </svg>
    //             ))}
    //           </div>
    //         </div>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Cleanliness</label>
    //           <div className="flex justify-center items-center">
    //             {[1, 2, 3, 4, 5].map((star) => (
    //               <svg
    //                 key={star}
    //                 className={`w-12 h-12 cursor-pointer transition-colors duration-300 ${cleanliness >= star ? 'text-mustard' : 'text-gray-300 hover:text-mustard'
    //                   }`}
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //                 onClick={() => handleRating(setCleanliness, star)}
    //               >
    //                 <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //               </svg>
    //             ))}
    //           </div>
    //         </div>
    //         <div className="text-center">
    //           <button onClick={nextStep} className="py-2 px-6 bg-mustard text-darkGreen font-semibold rounded">
    //             Next
    //           </button>
    //         </div>
    //       </div>
    //     )}

    //     {/* Step 2: Waiter and Feedback */}
    //     {step === 2 && (
    //       <div>
    //         <h2 className="text-2xl font-bold text-darkGreen mb-4 text-center">Provide Feedback</h2>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Waiter Name (optional)</label>
    //           <Select
    //             value={selectedWaiter}
    //             onChange={(selectedOption) => setSelectedWaiter(selectedOption)}
    //             options={waiters}
    //             placeholder="Select or search for the waiter"
    //             isSearchable
    //           />
    //         </div>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Your Feedback (optional)</label>
    //           <textarea
    //             rows={4}
    //             value={comment}
    //             onChange={(e) => setComment(e.target.value)}
    //             placeholder="Leave your message here (optional)"
    //             className="w-full p-4 text-darkGreen rounded-xl border border-gray-300 focus:ring-2 focus:ring-mustard focus:outline-none transition duration-300 resize-none"
    //           />
    //         </div>
    //         <div className="flex justify-between">
    //           <button onClick={prevStep} className="py-2 px-6 bg-gray-300 text-darkGreen font-semibold rounded">
    //             Back
    //           </button>
    //           <button onClick={nextStep} className="py-2 px-6 bg-mustard text-darkGreen font-semibold rounded">
    //             Next
    //           </button>
    //         </div>
    //       </div>
    //     )}

    //     {/* Step 3: Recommendation and Additional Comments */}
    //     {step === 3 && (
    //       <div>
    //         <h2 className="text-2xl font-bold text-darkGreen mb-4 text-center">Final Thoughts</h2>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Would you recommend us?</label>
    //           <div className="flex justify-center space-x-4">
    //             <button
    //               className={`px-4 py-2 rounded ${recommend === true ? 'bg-green-500 text-white' : 'bg-gray-300'}`}
    //               onClick={() => setRecommend(true)}
    //             >
    //               Yes
    //             </button>
    //             <button
    //               className={`px-4 py-2 rounded ${recommend === false ? 'bg-red-500 text-white' : 'bg-gray-300'}`}
    //               onClick={() => setRecommend(false)}
    //             >
    //               No
    //             </button>
    //           </div>
    //         </div>
    //         <div className="mb-6">
    //           <label className="block text-darkGreen font-semibold mb-2">Additional Comments (optional)</label>
    //           <textarea
    //             rows={4}
    //             value={additionalComment}
    //             onChange={(e) => setAdditionalComment(e.target.value)}
    //             placeholder="Let us know if there's anything else you'd like to share"
    //             className="w-full p-4 text-darkGreen rounded-xl border border-gray-300 focus:ring-2 focus:ring-mustard focus:outline-none transition duration-300 resize-none"
    //           />
    //         </div>
    //         <div className="flex justify-between">
    //           <button onClick={prevStep} className="py-2 px-6 bg-gray-300 text-darkGreen font-semibold rounded">
    //             Back
    //           </button>
    //           <button onClick={nextStep} className="py-2 px-6 bg-mustard text-darkGreen font-semibold rounded">
    //             Next
    //           </button>
    //         </div>
    //       </div>
    //     )}

    //     {/* Step 4: Review & Submit */}
    //     {step === 4 && (
    //       <div>
    //         <h2 className="text-2xl font-bold text-darkGreen mb-4 text-center">Review Your Feedback</h2>
    //         {/* Display Review Information */}
    //         <ul className="list-disc px-6">
    //           <li>Overall Experience: {rating} stars</li>
    //           <li>Food Quality: {foodQuality} stars</li>
    //           <li>Service Speed: {serviceSpeed} stars</li>
    //           <li>Cleanliness: {cleanliness} stars</li>
    //           {selectedWaiter && <li>Waiter: {selectedWaiter.label}</li>}
    //           {comment && <li>Feedback: {comment}</li>}
    //           <li>Recommend: {recommend ? 'Yes' : 'No'}</li>
    //           {additionalComment && <li>Additional Comments: {additionalComment}</li>}
    //         </ul>

    //         <div className="flex justify-between mt-6">
    //           <button onClick={prevStep} className="py-2 px-6 bg-gray-300 text-darkGreen font-semibold rounded">
    //             Back
    //           </button>
    //           <button onClick={handleSubmit} className="py-2 px-6 bg-mustard text-darkGreen font-semibold rounded">
    //             Submit
    //           </button>
    //         </div>

    //         {message && (
    //           <div className="text-center mt-6">
    //             <p className={`text-lg ${message.includes('success') ? 'text-green-500' : 'text-red-500'}`}>
    //               {message}
    //             </p>
    //           </div>
    //         )}
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
};

export default FeedbackForm;
