import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import your existing components
import SubmitFeedback from './components/SubmitFeedback';
import TableList from './components/TableList';
import TableLandingPage from './components/TableLandingPage';

// Import the new HomePage and NotFoundPage components
import HomePage from './components/HomePage';
import NotFoundPage from './components/404';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* HomePage as the root */}
        <Route path="/" element={<HomePage />} />

        {/* Define the route for the feedback form */}
        <Route path="/restaurant-feedback/table/:tableId" element={<SubmitFeedback />} />

        {/* Landing page for tables */}
        <Route path="/welcome" element={<TableLandingPage />} />

        {/* Table list */}
        <Route path="/tables" element={<TableList />} />

        {/* 404 - Catch-all route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
